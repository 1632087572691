/* eslint-disable no-lone-blocks */
import React, { useState, useRef } from 'react';
import './SelectUserType.css';
import Header from '../../Containers/Header';
import parkingBgImg from '../../assets/parkingBgImg.png';
//import TMCEOCIcon from '../../assets/TMC-EOC-ICon.png';
import registerDriveIcon from '../../assets/registerDriveIcon.png';
 
import registerLMCIcon from '../../assets/registerLMC-Icon.png';
import { HashLink } from 'react-router-hash-link';
import { languageJson } from '../../StringVariable';
import { submitGAEvent } from 'src/utils/GAEvents';

const SelectUserType = () => {    
    const checkbox1 = useRef()
    const checkbox2 = useRef()
    //const checkbox3 = useRef()
    //const checkbox4 = useRef()
    const [registerAsUserCheck, setRegisterAsUserCheck] = useState(false)
    const [registerAsLmcCheck, setRegisterAsLmcCheck] = useState(false)
    {/*  TODO 19 JULY */}
    //const [registerAsTmcCheck, setRegisterAsTmcCheck] = useState(false)
   // const [registerAsManagementCheck, setRegisterAsManagementCheck] = useState(false)
    const handleFirstInputClick = (e) => {
        if (checkbox1.current)
            if (checkbox1.current.checked) {
                submitGAEvent("BECOME A TRUCK DRIVER USER");
                setRegisterAsUserCheck(true)
                setRegisterAsLmcCheck(false)
             {/*  TODO 19 JULY */}
                //   setRegisterAsTmcCheck(false)
              //  setRegisterAsManagementCheck(false)
                checkbox2.current.checked = false;
             //   checkbox3.current.checked = false;
                //checkbox4.current.checked = false;
            }
            else {
                setRegisterAsUserCheck(false)
            }
    }
    const handleSecondInputClick = (e) => {
        if (checkbox1.current)
            if (checkbox2.current.checked) {
                submitGAEvent("BECOME AN LMC USER");
                setRegisterAsUserCheck(false)
                setRegisterAsLmcCheck(true)
               {/*  TODO 19 JULY */}
                // setRegisterAsTmcCheck(false)
             //   setRegisterAsManagementCheck(false)
                checkbox1.current.checked = false;
              //  checkbox3.current.checked = false;
               // checkbox4.current.checked = false;
            }
            else {
                setRegisterAsLmcCheck(false)
            }
    }
     
/*  TODO 19 JULY


    const handleThirdInputClick = (e) => {
        submitGAEvent("BECOME A TMC/EOC OPERATOR");
        if (checkbox3.current.checked) {
            setRegisterAsUserCheck(false)
            setRegisterAsLmcCheck(false)
            setRegisterAsTmcCheck(true)
           // setRegisterAsManagementCheck(false)
            checkbox1.current.checked = false;
            checkbox2.current.checked = false;
           // checkbox4.current.checked = false;
        }
        else {
            setRegisterAsTmcCheck(false)
        }
    }*/

    //TODO 5 
    // const handleFourthInputClick = (e) => {
    //     if (checkbox4.current.checked) {
    //         submitGAEvent("BECOME A PORT STAFF USER");
    //         setRegisterAsManagementCheck(true)
    //         setRegisterAsUserCheck(false)
    //         setRegisterAsLmcCheck(false)
    //         setRegisterAsTmcCheck(false)
    //         checkbox1.current.checked = false;
    //         checkbox2.current.checked = false;
    //         checkbox3.current.checked = false;
    //     }
    //     else {
    //         setRegisterAsManagementCheck(false)
    //     }
    // }

    return (
        <div>
            <Header />
            <div className="headerBannerMainDiv"> <img className="vessel_img" src={parkingBgImg} alt="vessel" /></div>
            <div className="center-underline text-center" id="selectusertype">
                <h2 className=" loginhead  pt-5">{languageJson.registerOption.selectusertype}</h2>
            </div>
            <div className="container my-5 text-center">
                <section className={`loginsection selectUserTypeMainDiv ${registerAsUserCheck ? 'selectedUserBorder' : ''}`}>
                    <div className="col-lg-12">
                        <div className="driverTitleDiv">
                            <img src={registerDriveIcon} alt='icon'></img>
                            <h2>{languageJson.registerOption.register_as_driver_txt}</h2>
                            <p>{languageJson.registerOption.TermsofUse_PrivacyPolicy_tick_txt}</p>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input checkbox-size" name="checkbox1" id="customCheck1" onClick={handleFirstInputClick} ref={checkbox1} />
                            <label className="custom-control-label">{languageJson.registerOption.agreetothe_txt} <a href="/#/userAgreement" rel="noreferrer" className="aggrementLink">{languageJson.registerOption.UserAgreement_txt}</a></label>
                        </div>
                        <HashLink to="/aggrement/driverRegistration#driverRegistration">   <button disabled={!registerAsUserCheck} className={`${!registerAsUserCheck ? 'selectedUserButton' : ''}`}>{languageJson.registerOption.register_txt}</button>  </HashLink>
                    </div>
                </section>


                <section className={`loginsection selectUserTypeMainDiv ${registerAsLmcCheck ? 'selectedUserBorder' : ''}`}>
                    <div className="col-lg-12">
                        <div className="driverTitleDiv">
                            <img src={registerLMCIcon} alt='icon'></img>
                            <h2>{languageJson.registerOption.Register_LMCUser_txt}</h2>
                            <p>{languageJson.registerOption.TermsofUse_PrivacyPolicy_tick_txt}</p>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input checkbox-size" id="customCheck2" name="checkbox2" onClick={handleSecondInputClick} ref={checkbox2} />
                            <label className="custom-control-label">{languageJson.registerOption.agreetothe_txt} <a href="/#/userAgreement" rel="noreferrer" className="aggrementLink">{languageJson.registerOption.UserAgreement_txt}</a></label>
                        </div>
                        <HashLink to="/aggrement/lmcregister#lmcregister"><button disabled={!registerAsLmcCheck} className={`${!registerAsLmcCheck ? 'selectedUserButton' : ''}`}>{languageJson.registerOption.register_txt}</button></HashLink>
                    </div>
                </section>

{/*  TODO 19 JULY */}
                {/* <section className={`loginsection selectUserTypeMainDiv ${registerAsTmcCheck ? 'selectedUserBorder' : ''}`}>
                    <div className="col-lg-12">
                        <div className="driverTitleDiv">
                            <img src={TMCEOCIcon} alt='icon'></img>
                            <h2>{languageJson.registerOption.Register_TMC_EOCOperator_txt}</h2>
                            <p>{languageJson.registerOption.TermsofUse_PrivacyPolicy_tick_txt}</p>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input checkbox-size" id="customCheck3" name="checkbox3" onClick={handleThirdInputClick} ref={checkbox3} />
                            <label className="custom-control-label">{languageJson.registerOption.agreetothe_txt} <a href="/#/userAgreement" rel="noreferrer" className="aggrementLink">{languageJson.registerOption.UserAgreement_txt}</a></label>
                        </div>
                        <HashLink to="/aggrement/publicregister#publicregister"><button disabled={!registerAsTmcCheck} className={`${!registerAsTmcCheck ? 'selectedUserButton' : ''}`}>{languageJson.registerOption.register_txt}</button>
                        </HashLink>
                    </div>
                </section> */}

                {/* 
                TODO 5 May
                
                <section className={`loginsection selectUserTypeMainDiv ${registerAsManagementCheck ? 'selectedUserBorder' : ''}`}>
                    <div className="col-lg-12">
                        <div className="driverTitleDiv">
                            <img src={registerTMSIcon} alt="icon" />
                            <h2>{languageJson.registerOption.RegisterMTC_TrafficManagementStaff_txt}</h2>
                            <p>{languageJson.registerOption.TermsofUse_PrivacyPolicy_tick_txt}</p>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input checkbox-size" id="customCheck4" name="checkbox4" onClick={handleFourthInputClick} ref={checkbox4} />
                            <label className="custom-control-label">{languageJson.registerOption.agreetothe_txt} <a href="/#/userAgreement" rel="noreferrer" className="aggrementLink">{languageJson.registerOption.UserAgreement_txt}</a></label>
                        </div>
                        <HashLink to="/aggrement/publicregister#publicregister"><button disabled={!registerAsManagementCheck} className={`${!registerAsManagementCheck ? 'selectedUserButton' : ''}`}>{languageJson.registerOption.register_txt}</button>
                        </HashLink>
                    </div>
                </section> */}
            </div>
        </div>
    )
}

export default SelectUserType;
