import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import store from "store";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "./Containers/ScrollToTop";
import Home from "../src/Components/HomeComponents/Home";
import Footer from "./Containers/Footer";
import FrequentlyAskedQuestions from "./Components/FAQ/FrequentlyAskedQuestions";
import SearchPage from "./Components/Search/SearchPage";
import Aboutus from "./Components/Aboutus/Aboutus";
import VesselOperations from "./Components/Vessel_Operations/VesselOperations";
import DriverRegistration from "./Components/DriverRegistration/DriverRegistration";
import RailwaysCrossing from "./Components/RailwaysCrossings/RailwaysCrossing";
import Announcements from "./Components/AlertsAnnouncements/AlertsAnnouncements";
import MarineTerminal from "./Components/MarineTerminalsDetail/MarineTerminalDetail";
import Resource from "./Components/Resources/Resource";
import UserLogin from "./Components/UserLogin/UserLogin";
import Alerts from "./Components/Alerts/Alerts";
import TruckTurnTime from "./Components/TruckTurnTime/TruckTime";
import TrafficCameras from "./Components/TrafficCameras/TrafficCameras";
import SecuritySafety from "./Components/SecuritySafety/SecuritySafety";
import SelectUserType from "./Components/SelectUserType/SelectUserType";
import CamerasPreview from "./Components/CamerasPreview/CamerasPreview";
import NewsAndUpdatesDetails from "./Components/NewsAndUpdatesDetail/NewsAndUpdatesDetails";
import ForgotPassword from "./Components/ForgotPassword/ForgotPassword";
import ChangePassword from "./Components/ForgotPassword/ChangePassword";
import NewsAndUpdateReadMore from "./Containers/NewsAndUpdateReadMore";
import LMCRegister from "./Components/LMCRegister/LMCRegisterPage";
import NewsDetailPage from "./Components/NewsDetailPage/NewsDetailPage";
import PublicRegistration from "./Components/PublicRegistration/PublicRegistration";
import PageNotFound from "./Components/PageNotFound/PageNotFound";
import { publicUrl } from "./redux/actions/PublicUrlAction";
import { useDispatch } from "react-redux";
import "./admin/scss/style.scss";
import { firebaseConfig, onMessageListener, gettoken } from "./firebaseConfig";
import Disclaimer from "./Components/LegalNotices/Disclaimer";
import UserAgreement from "./Components/LegalNotices/UserAgreement";
import ConditionsofUse from "./Components/LegalNotices/ConditionsofUse";
import { getGuestUser } from "./redux/actions/GuestUserAction";
import HistoricalTruckTurnTime from "./Components/HistoricalTruckTurnTime/HistoricalTruckTurnTime";
import HistoricalTruckTurnTimeFilter from "./Components/HistoricalTruckTurnTimeFilter/HistoricalTruckTurnTime";
import * as firebase from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import OfflineModal from "./offLineModal";
import OperationalReports from "./Components/Reports/OperationalReports";
import HomePage from "./Components/HomeComponents/HomePage";
import HistoricalTurnTimes from "./Components/Vessel_Operations/HistoricalTurnTimes";
import { Helmet } from 'react-helmet';
import VesselScheduleAdvent from "./Components/Vessel_Operations/VesselScheduleAdvent";
import PortSecurityandSafety from "./Components/Aboutus/PortSecurityandSafety";
import ReleaseNote from "./Components/ReleaseNotes/index";
import MarineTerminalCalendar from "./Components/Vessel_Operations/MarineTerminalCalendar";
import ViewImage from "./Components/FAQ/ViewImage";
import FloatingLayout from "./Components/Resources/FloatingLayout";
import ERI from "./Components/ERI/eri";
import ERISoon from "./Components/ERI/erisoon"
const DefaultLayout = React.lazy(() => import("./admin/layout/DefaultLayout"));
const app = firebase.initializeApp(firebaseConfig);
getAnalytics(app);
const App = () => {
  // Check user online or offline
  let [online, isOnline] = useState(navigator.onLine);
  const setOnline = () => {
    isOnline(true);
  };
  const setOffline = () => {
    isOnline(false);
  };

  useEffect(() => {
    window.addEventListener("offline", setOffline);
    window.addEventListener("online", setOnline);

    // cleanup if we unmount
    return () => {
      window.removeEventListener("offline", setOffline);
      window.removeEventListener("online", setOnline);
    };
  }, []);
  useEffect(() => {
    let token;
    async function tokenFunc() {
      token = await gettoken();
      if (token) {
        let data = { firebase_id: token };
        dispatch(getGuestUser(data));
      }
      return token;
    }
    tokenFunc();

    if (localStorage.getItem("lang") == null) {
      localStorage.setItem("lang", "en");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  onMessageListener().then((payload) => {
    //  console.log("payload--",payload);
  });

  const dispatch = useDispatch();
  useEffect(() => {
    let controler = true;
    if (controler) {
      dispatch(publicUrl());
    }
    return () => {
      controler = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // { Private routes}
  const PrivateRoute = ({ component: Component, ...rest }) => {
    const renderFunc = (props) => {
      const token = store.get("token");
      if (token) {
        return <Component {...props} />;
      }
      return <Redirect to={{ pathname: "/" }} />;
    };
    return <Route {...rest} render={renderFunc} />;
  };
  // { Public routes for auth}

  const PublicOnlyRoute = ({ component: Component, ...rest }) => {
    const renderFunc = (props) => {
      const token = store.get("token");
      if (!token) {
        return <Component {...props} />;
      } else {
        return <Redirect to={{ pathname: `/` }} />;
      }
    };
    return <Route {...rest} render={renderFunc} />;
  };
  // { Public routes}
  const GlobalOnlyRoute = ({ component: Component, ...rest }) => {
    const renderFunc = (props) => <Component {...props} />;
    return <Route {...rest} render={renderFunc} />;
  };

  let lang = localStorage.getItem("lang") || "en";
  return (
    <div
      className={
        lang === "es"
          ? "App spanishString"
          : lang === "pa"
            ? " App punjabiString"
            : "App"
      }
    >
      <Helmet>
        {/* <meta http-equiv="Content-Security-Policy" content="
    default-src 'self' https://fonts.gstatic.com https://cdnjs.cloudflare.com https://cdn0.iconfinder.com/ https://www.arcgis.com/;
    img-src 'self' data: https://oaklandportalstage-api.portofoakland.com https://oaklandport-bucket.s3.us-west-1.amazonaws.com https://cdn0.iconfinder.com/ https://maps.gstatic.com https://maps.googleapis.com;
    script-src 'self' 'unsafe-inline' https://www.googletagmanager.com https://www.google-analytics.com https://www.google.com https://www.google.com/cse/static/element/2b35e7a15e0e30e2/cse_element__en.js https://maps.googleapis.com;
    style-src 'self' 'unsafe-inline' https://www.google.com https://www.google.com/cse/static/element/2b35e7a15e0e30e2/default+en.css https://www.google.com/cse/static/style/look/v4/default.css https://cdnjs.cloudflare.com https://use.fontawesome.com https://fonts.googleapis.com;
    connect-src 'self' https://oaklandportalstage-api.portofoakland.com http://localhost:8080 https://firebase.googleapis.com https://www.google-analytics.com https://www.google.com https://maps.googleapis.com;
    font-src 'self' https://fonts.gstatic.com https://cdnjs.cloudflare.com https://use.fontawesome.com;
    media-src 'self' https://oaklandportalstage-api.portofoakland.com https://oaklandport-bucket.s3.us-west-1.amazonaws.com;
    "/> */}

        {/*  <meta http-equiv="Strict-Transport-Security" content="max-age=31536000; includeSubDomains; preload" />
          <meta http-equiv="X-Frame-Options" content="SAMEORIGIN" />
          <meta http-equiv="X-Content-Type-Options" content="nosniff" />
          */}
        {/* <meta http-equiv="Referrer-Policy" content="same-origin" />
          <meta http-equiv="Permissions-Policy" content="camera=*,geolocation=*,microphone=*,autoplay=*,fullscreen=*,picture-in-picture=*,sync-xhr=*,encrypted-media=*" />
          <meta http-equiv="Content-Security-Policy" content="
  default-src 'self' https://fonts.gstatic.com https://cdnjs.cloudflare.com https://cdn0.iconfinder.com/ https://www.arcgis.com/;
  img-src 'self' data: https://oaklandportalstage-api.portofoakland.com https://oaklandport-bucket.s3.us-west-1.amazonaws.com https://cdn0.iconfinder.com/ https://maps.gstatic.com https://maps.googleapis.com;
  script-src 'self' 'unsafe-inline' https://www.googletagmanager.com https://www.google-analytics.com https://www.google.com https://www.google.com/cse/static/element/2b35e7a15e0e30e2/cse_element__en.js https://maps.googleapis.com;
  style-src 'self' 'unsafe-inline' https://www.google.com https://www.google.com/cse/static/element/2b35e7a15e0e30e2/default+en.css https://www.google.com/cse/static/style/look/v4/default.css https://cdnjs.cloudflare.com https://use.fontawesome.com https://fonts.googleapis.com;
  connect-src 'self' https://oaklandportalstage-api.portofoakland.com https://firebase.googleapis.com https://www.google-analytics.com https://www.google.com https://maps.googleapis.com https://firebaseinstallations.googleapis.com/; 
  font-src 'self' https://fonts.gstatic.com https://cdnjs.cloudflare.com https://use.fontawesome.com;
  media-src 'self' https://oaklandportalstage-api.portofoakland.com https://oaklandport-bucket.s3.us-west-1.amazonaws.com;
" /> */}

      </Helmet>
      <ScrollToTop>
        <div>
          <FloatingLayout />
        </div>
        <React.Suspense fallback={<div className="preloader"></div>}>
          <Switch>
            <PublicOnlyRoute path="/userLogin" exact component={UserLogin} />
            <PublicOnlyRoute
              path="/publicregister"
              exact
              component={PublicRegistration}
            />
            <PublicOnlyRoute
              path="/driverRegistration"
              exact
              component={DriverRegistration}
            />
            <PublicOnlyRoute
              path="/lmcregister"
              exact
              component={LMCRegister}
            />
            <PublicOnlyRoute
              path="/aggrement/publicregister"
              exact
              component={PublicRegistration}
            />
            <PublicOnlyRoute
              path="/aggrement/driverRegistration"
              exact
              component={DriverRegistration}
            />
            <PublicOnlyRoute
              path="/aggrement/lmcregister"
              exact
              component={LMCRegister}
            />
            <PublicOnlyRoute
              path="/selectusertype"
              exact
              component={SelectUserType}
            />
            <PublicOnlyRoute
              path="/forgotPassword"
              exact
              component={ForgotPassword}
            />
            <PublicOnlyRoute
              path="/changePassword"
              exact
              component={ChangePassword}
            />

            <GlobalOnlyRoute path="/" exact component={Home} />
            <GlobalOnlyRoute path="/aboutus" exact component={Aboutus} />
            <GlobalOnlyRoute
              path="/railwayCrossing"
              exact
              component={RailwaysCrossing}
            />
            <GlobalOnlyRoute
              path="/announcements"
              exact
              component={Announcements}
            />
            <GlobalOnlyRoute
              // path="/portSecurityandSafety"
              path="/PortHolidayCalendar"
              exact
              component={PortSecurityandSafety}
            />


            <GlobalOnlyRoute
              path="/marineTerminals/:id"
              exact
              component={MarineTerminal}
            />
            <GlobalOnlyRoute path="/resource" exact component={Resource} />
            <GlobalOnlyRoute
              path="/vesselOperation"
              exact
              component={VesselOperations}
            />
            <GlobalOnlyRoute path="/historicalTruckTurnTime" exact component={HistoricalTurnTimes} />
            <GlobalOnlyRoute path="/alerts" exact component={Alerts} />
            <GlobalOnlyRoute
              path="/truckTime"
              exact
              component={TruckTurnTime}
            />
            <GlobalOnlyRoute
              path="/cameras"
              exact
              component={CamerasPreview}
            />

            <GlobalOnlyRoute
              path="/trafficCameras"
              exact
              component={TrafficCameras}
            />
            <GlobalOnlyRoute
              path="/TruckRoutesComplianceInformation"
              exact
              component={SecuritySafety}
            />
            <GlobalOnlyRoute
              path="/faqs"
              exact
              component={FrequentlyAskedQuestions}
            />
            <GlobalOnlyRoute
              path="/viewImage"
              exact
              component={ViewImage}
            />
            <GlobalOnlyRoute path="/search" exact component={SearchPage} />
            <GlobalOnlyRoute
              path="/NewsAndUpdatesDetails"
              exact
              component={NewsAndUpdatesDetails}
            />
            <GlobalOnlyRoute
              path="/new-and-updates-readmore"
              exact
              component={NewsAndUpdateReadMore}
            />
            <GlobalOnlyRoute
              path="/news-Details/:id"
              exact
              component={NewsDetailPage}
            />
            <GlobalOnlyRoute
              path="/disclaimer"
              exact
              component={Disclaimer}
            />
            <GlobalOnlyRoute
              path="/userAgreement"
              exact
              component={UserAgreement}
            />
            <GlobalOnlyRoute
              path="/conditions-of-use"
              exact
              component={ConditionsofUse}
            />
            <GlobalOnlyRoute
              path="/operationalReports"
              exact
              component={OperationalReports}
            />

            <GlobalOnlyRoute path="/ViewTruckTurnTimes"
              exact
              component={HistoricalTurnTimes} />
            <GlobalOnlyRoute path="/MarineTerminalCalendar"
              exact
              component={MarineTerminalCalendar} />


            <GlobalOnlyRoute
              path="/historicalTruckTurnTime"
              exact
              component={HistoricalTruckTurnTime}
            />
            <GlobalOnlyRoute
              path="/VesselScheduleAdvent"
              exact
              component={VesselScheduleAdvent}
            />



            <GlobalOnlyRoute
              path="/historicalTruckTurnTimeFilter"
              exact
              component={HistoricalTruckTurnTimeFilter}
            />
            <GlobalOnlyRoute
              path="/releaseNotes"
              exact
              component={ReleaseNote}
            />
            <GlobalOnlyRoute
              path="/HomePage"
              exact
              component={HomePage}
            />
             <GlobalOnlyRoute
              path="/eri"
              exact
            component={ERISoon}
              // component={ERI}
            />
            <PrivateRoute
              path="/admin"
              name="Home"
              component={(props) => <DefaultLayout {...props} />}
            />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </React.Suspense>
      </ScrollToTop>
      <Footer />
      <OfflineModal display={!online} />
    </div>
  );
};

export default withRouter(App);
