import { combineReducers } from "redux";
import { userAccountReducer } from "./UserAccount";
import { loadingReducer } from "./LoadingReducer";
import { messageReducer } from "./MessageReducer";
import { statusCodeReducer } from "./StatusCodeReducer";
import { publicUrlReducer } from "./PublicUrlReducer";
import { tempStorageReducer } from "./TempStorageReducer";
import { alertDataReducer } from "./AlertDataReducer";
import { resourceDataReducer } from "./ResourceDataReducer";
import { userDetailReducer } from './UserDetail';
import { changeStateReducer } from './sidebar/changeStateReducer';
import { ParkingReducer } from './parking/Parkingreducer';
import { districtListReducer } from './admin/DistrictReducer';
import { MarineTerminalReducer } from './MarineTerminalReducer';
import { GuestUserReducer } from './GuestUserReducer';
import { DashBoardReducer } from './DashBoardReducer';
import { SecurityDepositReducer } from './SecurityDepositReducer';

const appReducer = combineReducers({
  auth: userAccountReducer,
  loadingState: loadingReducer,
  messageState: messageReducer,
  statusCodeState: statusCodeReducer,
  publicUrlState: publicUrlReducer,
  tempStorageState: tempStorageReducer,
  alertDataState: alertDataReducer,
  resourceDataState: resourceDataReducer,
  userDetail: userDetailReducer,
  changeState: changeStateReducer,
  parking: ParkingReducer,
  DistrictList: districtListReducer,
  MarineTerminalData: MarineTerminalReducer,
  GuestUserId: GuestUserReducer,
  DashBoardData: DashBoardReducer,
  SecurityDeposit:SecurityDepositReducer
});

const reducers = (state, action) => {
  let nextState = state;
  return appReducer(nextState, action);
};

export default reducers;