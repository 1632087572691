import React from "react";
import Header from "src/Containers/Header";
import { Link } from "react-router-dom";
import { languageJson } from "src/StringVariable";
import AnnouncementAlerts from "./Announcements";
// import portimg1 from "../../assets/1.jpg";
// import portimg2 from "../../assets/2.jpg";
//  import portimg5 from "../../assets/5.jpg";
import POAKPanorama from "../../assets/POAKPanorama.jpg";
 
function Announcements() {
    return (
        <div>
            <Header />
            <div className="headerBannerMainDiv">
                <img className="banner_img" src={POAKPanorama} alt="" />
            </div>
            

            <div className="vo-bg">
                <div className="container-fluid row py-4 ">
                    <div>
                        <Link to="/">
                            {" "}
                            <span className="vo-menu pe-2">
                                {languageJson.home_txt}{" "}
                            </span>{" "}
                        </Link>
                        <span className="text-dark ps-2">
                            {" "}
                            / &nbsp; {languageJson.alert_announcement_txt}
                        </span>
                    </div>
                </div>
            </div>

            {/* ------------------------------------------------------ */}
            <div className="railCrossinglRoutesMainDiv alertAnnouncementsTabMain">
                <section className="railcrossing-head container py-5">
                    <AnnouncementAlerts alertAnnouncement={true} pagination={true} />
                </section>
                {/* ----------------------------------------------------------------------------------------- */}
            </div>
            {/* ----------------------------------------------------------------------------------------- */}
        </div>
    );
}

export default Announcements;
