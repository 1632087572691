import { ActionTypes } from "../constants/Types";
const initialState = {};

export const SecurityDepositReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_SECURITY_DEPOSIT_DATA_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
