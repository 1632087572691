
import "./Aboutus.css";
 
import ComingSoon from "../../utils/ComingSoon";
 
 
function TheComingSoon() {
    
  return (
   
      
      <div className="railCrossinglRoutesMainDiv" style={{marginTop:"-100px"}}>
        <section className="railcrossing-head container py-5">
          <div className="row container-fluid py-5 tabVesselDiv">
            <div className="d-flex justify-content-center">
            <div className="truckTurnTimeMainDiv historicalTTTMainDiv">
            <div className="container">
          <div className="col-lg-12 col-md-12 col-md-12">
          <ComingSoon/>
       
           </div>
           </div>
           </div>
           </div>
           </div>
           </section>

          
            </div>
      
     
  );
}
export default TheComingSoon;
