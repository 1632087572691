/* eslint jsx-a11y/anchor-is-valid: 0 */
/* eslint-disable no-script-url*/
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "../assets/script";
import "../App.css";
import oaklandlogo from "../assets/new_logo.png";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { languageJson } from "../StringVariable";
import { userLogout } from "src/redux/actions/UserAccount";
import { HashLink } from "react-router-hash-link";
import HomeLanguageChange from "src/utils/HomeLanguageChanges";
import { getUserSetting, UpdateUserSetting } from 'src/redux/actions/UserAccount'
import { getAnalytics, logEvent } from "firebase/analytics";
import { submitGAEvent } from "src/utils/GAEvents";
import ScrollPageWithScreen from 'src/Containers/ScrollPageResponsive'

const Header = (props) => {
  const analytics = getAnalytics();
  let dispatch = useDispatch();
  let history = useHistory();
  const [language, setLanguage] = useState(
    ["es", "en", "pa"].includes(localStorage.getItem("lang")) ? localStorage.getItem("lang") : "en"
  );
  const [setting, setSetting] = useState(null);
  let { userDetail } = useSelector((state) => state);
  const [newList, setNewList] = useState([]);
  const location = useLocation();

  const [path, setPath] = useState();
  const [toggle, setToggel] = useState(false);
  let token = localStorage.getItem("token");
  let istoken = token ? true : false;

  const getThePathName = () => {
    const { pathname } = location;
    setPath(pathname);
  };
  const handleHandBurgerMenu = () => {
    let bodyElement = document.getElementsByTagName('body')[0];
    bodyElement.className = "noscrolldiv";
    setToggel(!toggle);
  };
  const handleHandBurgerMenuClose = () => {
    let bodyElement = document.querySelector('body') // Get element from DOM
    bodyElement.classList.remove('noscrolldiv');
    setToggel(false);
  };

  

  useEffect(() => {
    let controller = true;
    if (controller) {
      getThePathName();
      if (token && userDetail && userDetail.userSetting == null) {
        dispatch(getUserSetting())
      }
    }
    return () => {
      controller = false;
    };
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (userDetail && userDetail.userSetting != null) {
      setSetting(userDetail.userSetting);

      let list = [];
      userDetail.userSetting["traffic_cameras"].map(
        (item) => item.IsSelected && list.push(item.Name)
      );
      setNewList(list)
      localStorage.setItem("lang", userDetail.userSetting.language);
    }
  }, [userDetail]);
  const logout = () => {
    submitGAEvent("LOGOUT");
    dispatch(userLogout(history));
  };
  const handleChange = (e) => {
    logEvent(analytics, 'USER_APP_LANGUAGE', { name: localStorage.getItem('lang') || "en" });
    setLanguage(e.target.value);
    localStorage.setItem("lang", e.target.value);
    if (token && setting !== null) {
      let newSetting = {
        ...setting,
        language: e.target.value,
        traffic_cameras: newList.toString()
      }
      dispatch(UpdateUserSetting(newSetting, true));
    } else {
      window.location.reload();
    }
  };
  const redirectToSearch = () => {
    submitGAEvent("SEARCH");
    history.push("/search")
    window.location.reload();
  }
  return (
    <div>
     {location.pathname != '/releaseNotes' && <ScrollPageWithScreen/> }
      {/* <div className="container-fluid blacknav fixedTopHeaderBar fixed">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-8 blank_md8Div"></div>

            <div className="col-md-1 custom-border">
              <HomeLanguageChange
                value={language}
                name={"language"}
                onchange={(e) => {
                  handleChange(e);
                }}
              />
            </div>
            {!istoken ? (
              <React.Fragment>
                {" "}
                <div className="col-md-1 custom-border">
                  <HashLink
                    to="/userLogin#loginpage"
                    className="text-white text-decoration-none"
                  >
                    {languageJson.userAuth.login}
                  </HashLink>
                </div>
                <div className="col-md-1">
                  <HashLink
                    to="/selectusertype#selectusertype"
                    className="text-white text-decoration-none"
                  >
                    {languageJson.userAuth.register}
                  </HashLink>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {" "}
                <div className="col-md-1 custom-border">
                  <Link
                    to="/admin/dashboard"
                    className="text-white text-decoration-none"
                  >
                    {languageJson.navbarName.dashborad_txt}
                  </Link>
                </div>
                <div className="col-md-1">
                <a style={{cursor: "pointer"}}
                    onClick={() => logout()}
                    className="text-white text-decoration-none"
                  >
                    {languageJson.userAuth.logout}
                  </a>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div> */}
      {toggle && toggle ? (
        <>
          <div id="myNav" className="overlay-toggel">
            <a
              href="javascript:void(0)"
              className="closebtn"
              onClick={() => {
                handleHandBurgerMenuClose();
              }}
            >
              &times;
            </a>
            <div className="overlay-content" onClick={() => handleHandBurgerMenuClose()}>
              <Link to="/">{languageJson.navbarName.home}</Link>
              <Link to="/aboutus">{languageJson.navbarName.about}</Link>
              <Link to="/resource">{languageJson.navbarName.resources}</Link>
              <Link to={{
                      pathname: "/ViewTruckTurnTimes",
                      state: "reports"
                    }}>{languageJson.navbarName.reports}</Link>
                    
              <Link to="/alerts">{languageJson.navbarName.alerts}</Link>
              <Link to="/announcements">{languageJson.alertsAnnouncements}</Link>
         
              {/* <HomeLanguageChange
                value={language}
                name={"language"}
                onchange={(e) => {
                  handleChange(e);
                }}
              />
              {!istoken ? (
                <React.Fragment>
                  <Link to="/userLogin">{languageJson.userAuth.login}</Link>
                  <Link to="/selectusertype">
                    {languageJson.userAuth.register}
                  </Link>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Link to="/admin/dashboard" className="text-decoration-none">
                    {languageJson.navbarName.dashborad_txt}
                  </Link>
                  <a style={{cursor: "pointer"}}
                   
                    onClick={() => {
                      dispatch(userLogout(history));
                    }}
                  >
                    {languageJson.userAuth.logout}
                  </a>
                </React.Fragment>
              )} */}
            </div>
          </div>
        </>
      ) : null}

      <nav className="navbar navbar-expand-lg justify-content-center navcustomclass navbar-light nav-fixed">
        <div className="container nav-mobile d-flex justify-content-between">
          <div>
            <Link to="/">
              {" "}
              <div>
                {" "}
                <img
                  src={oaklandlogo}
                  alt="mobileLogo"
                  className="logo-mobile"
                />{" "}
              </div>
            </Link>
          </div>
          <div>
          {/* TODO SEARCH */}
            {/* <Link onClick={redirectToSearch} className="mobileSearchIcon ">
              <i className="fa fa-search " aria-hidden="true"></i>
            </Link> */}
            <button
              className="navbar-toggler"
              type="button"
              onClick={handleHandBurgerMenu}
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavAltMarkup"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div
            className="collapse navbar-collapse navmob   navNewMenuDiv"
            id="navbarNavAltMarkup"
          >
            <ul className="navNewMenuListDiv">
              <li>
                <Link
                  to="/"
                  className={`nav-link ${path === "/" ? "active" : "text-dark"
                    }  line`}
                  aria-current="page"
                >
                  {languageJson.navbarName.home}
                </Link>
              </li>
              <li>
                <Link
                  to="/aboutus"
                  className={`nav-link ${path === "/aboutus" ? "active line" : "text-dark"
                    }`}
                >
                  {languageJson.navbarName.about}
                </Link>
              </li>
              <li>
                <Link
                  to="/resource"
                  className={`nav-link ${path === "/resource" ? "active line" : "text-dark"
                    }`}
                >
                  {languageJson.navbarName.resources}
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  className="nav-link homenav nav-txt-color"
                  href="#"
                >
                  <div
                    id="newheadnav"
                    className="customization_text nav-txt-color customization_text2"
                  >
                    <img
                      src={oaklandlogo}
                      alt="oaklandlogo"
                      id="inner"
                      className="d-block"
                    />
                  </div>
                </Link>
                <div className="customization_text2 d-none">
                  <p className="nav-link imgmargin">
                    {" "}
                    <Link to="/">
                      <img
                        src={oaklandlogo}
                        alt="oaklandlogo"
                        className="d-block "
                      />
                    </Link>
                  </p>
                </div>
              </li>
              <li>
              <Link to={{
                      pathname: "/ViewTruckTurnTimes",
                      state: "reports"
                    }}
                  className={`nav-link ${path === "/ViewTruckTurnTimes" && props?.tab ==='reports' ? "active line" : "text-dark"
                    }`}
                >
                  {languageJson.navbarName.reports}
                </Link>
              </li>

              <li>
                {" "}
                <Link
                  to="/alerts"
                  className={`nav-link ${path === "/alerts" ? "active line" : "text-dark"
                    }`}
                >
                  {languageJson.navbarName.alerts}
                </Link>
              </li>
              <li>
                <Link
                  to="/announcements"
                  className={`nav-link ${path === "/announcements" ? "active line" : "text-dark"
                    }`}
                >
                  {languageJson.alertsAnnouncements}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
