/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useState, useEffect, useMemo } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../../assets/script";
import Header from "../../Containers/main-header/Header";
import NavBar from "../../Containers/main-header/NavBar";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../redux/actions/UserAccount";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { languageJson } from "../../StringVariable";
import HomeLanguageChange from "src/utils/HomeLanguageChanges";
import MarineTerminal from "../../Containers/MarineTerminal";
import Tools from "../../Containers/Tools";
import FrequentlyAskedQuestions from "../../Containers/FrequentlyAskedQuestions";

import NewsUpdates from "../../Containers/NewsUpdates";
import { getUserSetting, UpdateUserSetting } from "src/redux/actions/UserAccount";
import { submitGAEvent } from "src/utils/GAEvents";

const HomePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [headerFixed, setHeaderFixed] = useState(false);
  const [language, setLanguage] = useState(
    ["es", "en", "pa"].includes(localStorage.getItem("lang"))
      ? localStorage.getItem("lang")
      : "en"
  );
  const [setting, setSetting] = useState(null);
  let { userDetail } = useSelector((state) => state);
  const [newList, setNewList] = useState([]);
  let token = localStorage.getItem("token");
  let istoken = token ? true : false;

  const onScroll = () => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 580) {
        setHeaderFixed(true);
      } else {
        setHeaderFixed(false);
      }
    });
  };

  useEffect(() => {
    submitGAEvent("HOME");
  }, []);

  const handleChange = (e) => {
    setLanguage(e.target.value);
    localStorage.setItem("lang", e.target.value);
    if (token && setting !== null) {
      let newSetting = {
        ...setting,
        language: e.target.value,
        traffic_cameras: newList.toString(),
      };
      dispatch(UpdateUserSetting(newSetting, true));
    } else {
      window.location.reload();
    }
  };

  useEffect(() => {
    let controler = true;
    if (controler) {
      onScroll();
      if (token) {
        dispatch(getUserSetting());
      }
    }
    return () => {
      controler = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userDetail && userDetail.userSetting != null) {
      setSetting(userDetail.userSetting);
      let list = [];
      userDetail.userSetting["traffic_cameras"].map(
        (item) => item.IsSelected && list.push(item.Name)
      );
      setNewList(list);
      localStorage.setItem("lang", userDetail.userSetting.language);
    }
  }, [userDetail]);

  // onclick Logout functionlity 
  const logout = () => {
    submitGAEvent("LOGOUT");
    dispatch(userLogout(history));
  };

  //Redirect to search page
  const redirectToSearch = () => {
    history.push("/search");
    window.location.reload();
  };
  
  // usememo component for stop rerendder component
  const header = useMemo(() => <Header />, []);
  const marineTerminal = useMemo(() => <MarineTerminal />, []);
  const newsUpdates = useMemo(() => <NewsUpdates />, []);
  const frequentlyAskedQuestions = useMemo(
    () => <FrequentlyAskedQuestions />,
    []
  );

  return (
    <React.Fragment>
      {headerFixed ? (
        <div>
          {/* <div className="container-fluid blacknav fixedTopHeaderBar fixed">
            <div className="col-md-12 blacknav">
              <div className="row">
                <div className="col-md-8 blank_md8Div"></div>
                 
                <div className="col-md-1 custom-border">
                  <HomeLanguageChange
                    value={language}
                    name={"language"}
                    onchange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>
                {!istoken ? (
                  <React.Fragment>
                    <div className="col-md-1 custom-border">
                      <HashLink
                        to="/userLogin#loginpage"
                        className="text-white text-decoration-none"
                      >
                        {languageJson.userAuth.login}
                      </HashLink>
                    </div>
                    <div className="col-md-1">
                      <HashLink
                        to="/selectusertype#selectusertype"
                        className="text-white text-decoration-none"
                      >
                        {languageJson.userAuth.register}
                      </HashLink>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="col-md-1 custom-border">
                      <Link
                        to="/admin/dashboard"
                        className="text-white text-decoration-none"
                      >
                        {languageJson.navbarName.dashborad_txt}
                      </Link>
                    </div>
                    <div className="col-md-1">
                    <a style={{cursor: "pointer"}}
                        onClick={() => logout()}
                        className="text-white text-decoration-none"
                      >
                        {languageJson.userAuth.logout}
                      </a>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div> */}
        </div>
      ) : null}
      {header}
      <NavBar />
      {marineTerminal}
      <Tools />
      {newsUpdates}
      {frequentlyAskedQuestions}
     {/* <Findus /> */}
    </React.Fragment>
  );
};

export default HomePage;
