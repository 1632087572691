import { ActionTypes } from "../constants/Types";

export const startLoginLoading = () => {
    return {
        type: ActionTypes.START_LOGIN_LOADING
    }
}

export const otpSendLoading = () => {
    return {
        type: ActionTypes.OTP_SEND_LOADING
    }
}

export const driverRegisterLoading = () => {
    return {
        type: ActionTypes.DRIVER_REGISTER_LOADING
    }
}

export const otpVerifyLoading = () => {
    return {
        type: ActionTypes.OTP_VERIFY_LOADING
    }
}

export const stopLoading = () => {
    return {
        type: ActionTypes.STOP_LOADING
    }
}

export const lmcRegisterLoading = () => {
    return {
        type: ActionTypes.LMC_REGISTER_LOADING
    }
}

export const contactUsLoading = () => {
    return {
        type: ActionTypes.CONTACT_US_LOADING
    }
}

export const forgotPasswordLoading = () => {
    return {
        type: ActionTypes.FORGOT_PASSWORD_LOADING
    }
}

export const changePasswordLoading = () => {
    return {
        type: ActionTypes.CHANGE_PASSWORD_LOADING
    }
}

export const startVesselAlertsDataLoading = () => {
    return {
        type: ActionTypes.VASSEL_ALERTS_DATA_LOADING
    }
}

export const startRailCrossingAlertsDataLoading = () => {
    return {
        type: ActionTypes.RAIL_CROSSING_ALERTS_DATA_LOADING
    }
}
export const startAlertsAnnouncementsDataLoading = () => {
    return {
        type: ActionTypes.ALERTS_ANNOUNCEMENTS_DATA_LOADING
    }
}

export const startMarineTerminalAlertsDataLoading = () => {
    return {
        type: ActionTypes.MARINE_TERMINAL_ALERTS_DATA_LOADING
    }
}
export const startTrafficAndCamersAlertsDataLoading = () => {
    return {
        type: ActionTypes.TRAFFIC_AND_CAMERAS_ALERTS_DATA_LOADING
    }
}
export const securityAndSafetyAlertsDataLoading = () => {
    return {
        type: ActionTypes.SECURITY_AND_SAFETY_ALERT_DATA_LOADING
    }
}

export const startResourceDataLoading = () => {
    return {
        type: ActionTypes.RESOURCE_DATA_LOADING
    }
}

export const userDetailLoading = () => {
    return {
        type: ActionTypes.USER_DETAIL_LOADING
    }
}

export const startPortMessageLoading = () => {
    return {
        type: ActionTypes.PORT_MESSAGE_LOADING
    }
}

export const startPortConstructionEventsLoading = () => {
    return {
        type: ActionTypes.PORT_CONSTRUCTION_EVENTS_LOADING
    }
}

export const startPortIncidentClosuresLoading = () => {
    return {
        type: ActionTypes.PORT_INCIDENT_CLOSURES_LOADING
    }
}

export const startRegionalMessageSignsLoading = () => {
    return {
        type: ActionTypes.REGIONAL_MESSAGE_SIGNS_LOADING
    }
}

export const startRegionalConstructionEventsLoading = () => {
    return {
        type: ActionTypes.REGIONAL_CONSTRUCTION_EVENTS_LOADING
    }
}

export const startRegionalIncidentClosuresLoading = () => {
    return {
        type: ActionTypes.REGIONAL_INCIDENT_CLOSURES_LOADING
    }
}

export const startMarineTerminalListLoading = () => {
    return {
        type: ActionTypes.MARINE_TERMINAL_LIST_DATA_LOADING
    }
}

export const startReservationBooking = ()=>{
    return {
        type:ActionTypes.RESERVATION_BOOKING_LOADING
    }
}

export const districtMessageSignsLoading = () => {
    return {
        type: ActionTypes.DISTRICT_MESSAGE_SIGNS_LOADING
    }
}


export const deleteAccountLoading = () => {
    return {
        type: ActionTypes.DELETE_ACCOUNT_LOADING
    }
}

export const cancelDeleteAccountLoading = () => {
    return {
        type: ActionTypes.CANCEL_DELETE_ACCOUNT_LOADING
    }
}

