/* eslint-disable no-unused-vars */
import Header from "../../Containers/Header";
import { Link } from "react-router-dom";
import { languageJson } from "../../StringVariable";
import "./eri.css"; 

import portimg1 from "../../assets/RailCrossing/1.jpg"; 
import portimg2 from "../../assets/RailCrossing/2.jpg";
import portimg3 from "../../assets/RailCrossing/3.jpg";

import TheComingSoon from "../Aboutus/TheComingSoon";
 function ERISoon() {
  return (
    <div>
      <Header />
      <div className="image-row-banner">
             <img className="banner_img" src={portimg1} alt=""/>
             <img className="banner_img" src={portimg3} alt=""/> 
             <img className="banner_img" src={portimg2} alt=""/>      
      </div>
      <div className="vo-bg">
        <div className="container-fluid row py-4 ">
          <div>
            <Link to="/">
              {" "}
              <span className="vo-menu pe-2">
                {languageJson.home_txt}{" "}
              </span>{" "}
            </Link>
            <span className="text-dark ps-2">
              {" "}
              / &nbsp; {languageJson.empty_return_instructions}
            </span>
          </div>
        </div>
      </div>

      {/* ------------------------------------------------------ */}
      <div className="">
        <section className="container py-5">
          <div className="row container-fluid py-5 tabVesselDiv">
            <div className="d-flex justify-content-center">
            <div className="truckTurnTimeMainDiv historicalTTTMainDiv">
                <div className="container">
                    <div className="titleDescpDiv">
                        <h2>{languageJson.empty_return_instructions}</h2>
                    </div>
                    <div className="col-12 lastUpdateDiv">
            <div className="col-12 lastUpdateTitleDiv">
              <h3 className="col-12"> </h3>
                <div className="col-lg-12 col-md-12 col-md-12">
                        <TheComingSoon/>
                     
                         </div>
            </div>
          
        


          </div>
                </div>
            </div>
            </div>
          </div>
        
        </section>
      </div>
    </div>
  );
}

export default ERISoon;
