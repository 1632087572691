/* eslint-disable*/
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import oaklandlogo from '../../assets/new_logo.png'
import { Link } from 'react-router-dom';
import { languageJson } from '../../StringVariable';
import { userLogout, getUserSetting, UpdateUserSetting } from "src/redux/actions/UserAccount";
import { HashLink } from 'react-router-hash-link';
import HomeLanguageChange from "src/utils/HomeLanguageChanges";

const NavBar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [language, setLanguage] = useState(
    ["es", "en", "pa"].includes(localStorage.getItem("lang")) ? localStorage.getItem("lang") : "en"
  );
  const [newList, setNewList] = useState([]);
  const [setting, setSetting] = useState(null);
  const [headerFixed, setHeaderFixed] = useState(false)
  const [toggle, setToggle] = useState(false)
  let { userDetail } = useSelector((state) => state);
  let token = localStorage.getItem("token")
  let istoken = token ? true : false
  const onScroll = () => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 580) {
        setHeaderFixed(true)
      } else {
        setHeaderFixed(false)
      }
    });
  };

  const ScrollToTop = () => {
    window.scrollTo(0, 0);
  }
  const redirectToSearch = ()=>{
    history.push("/search")
    window.location.reload();
  }
  const handleChange = (e) => {
    setLanguage(e.target.value);
    localStorage.setItem("lang", e.target.value);
    if (token && setting !== null) {
      let newSetting = {
        ...setting,
        language: e.target.value,
        traffic_cameras: newList.toString()
      }
      dispatch(UpdateUserSetting(newSetting, true));
    } else {
      window.location.reload();
    }
  };
  const handleHandBurgerMenu = () => {
    let bodyElement = document.getElementsByTagName('body')[0];
    bodyElement.className = "noscrolldiv";
    setToggle(!toggle);
  };
  const handleHandBurgerMenuClose = () => {  
    let bodyElement =  document.querySelector('body') // Get element from DOM
    bodyElement.classList.remove('noscrolldiv') ;  
    setToggle(false);
  };
  useEffect(() => {
    if (userDetail && userDetail.userSetting != null) {
      setSetting(userDetail.userSetting);

      let list = [];
      userDetail.userSetting["traffic_cameras"].map(
        (item) => item.IsSelected && list.push(item.Name)
      );
      setNewList(list)
      localStorage.setItem("lang", userDetail.userSetting.language);
    }
  }, [userDetail]);
  useEffect(() => {
    if (token) {
      dispatch(getUserSetting())
    }
    onScroll();
  }, [])

  return (
    <>
      {
        toggle ? (
          <>
            <div id="myNav" className="overlay-toggel">
              <a href="javascript:void(0)" className="closebtn" onClick={() => handleHandBurgerMenuClose()}>&times;</a>
              <div className="overlay-content" onClick={() => handleHandBurgerMenuClose()}>
                <Link to="/">{languageJson.navbarName.home}</Link>
                <Link to="/aboutus">{languageJson.navbarName.about}</Link>
                <Link to="/resource">{languageJson.navbarName.resources}</Link>
                <Link to={{
                      pathname: "/ViewTruckTurnTimes",
                      state: "reports"
                    }}>{languageJson.navbarName.reports}</Link>
                <Link to="/alerts">{languageJson.navbarName.alerts}</Link>
                <Link to="/announcements">{languageJson.alertsAnnouncements}</Link>
               
                {/* <HomeLanguageChange
                  value={language}
                  name={"language"}
                  onchange={(e) => {
                    handleChange(e);
                  }}
                />
                {!istoken ? <React.Fragment>
                  <HashLink to="/userLogin#loginpage">{languageJson.userAuth.login}</HashLink>
                  <Link to="/selectusertype">{languageJson.userAuth.register}</Link>
                </React.Fragment> :
                  <React.Fragment>

                    <Link to="/admin/dashboard" className="text-decoration-none">{languageJson.navbarName.dashborad_txt}</Link>
                    <Link  style={{cursor: "pointer"}} onClick={() => { dispatch(userLogout(history)) }}>{languageJson.userAuth.logout}</Link>
                  </React.Fragment>
                } */}
              </div>
            </div>
          </>
        ) : null
      }
      <nav className={`navbar navbar-expand-lg justify-content-center navcustomclass navbar-light ${headerFixed ? 'nav-fixed' : ''}`} >
        < div className="container nav-mobile d-flex justify-content-between">

          <div className="">
            <Link to="/"> <div><img src={oaklandlogo} alt="scroll" onClick={() => { ScrollToTop() }} className="logo-mobile" /> </div></Link>
          </div>
          <div className="">
            {/* TODO SEARCH */}
          {/* <Link onClick={redirectToSearch} className="mobileSearchIcon ">
                <i className="fa fa-search " aria-hidden="true"></i>
              </Link> */}
            <button className="navbar-toggler" onClick={handleHandBurgerMenu} type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>

          <div className="collapse navbar-collapse navhide navNewMenuDiv" id="navbarNavAltMarkup">
            <ul className="navNewMenuListDiv">
              <li>
                <Link to="/" className={`nav-link active navhover homenav  line  ${headerFixed ? 'nav-txt-color' : 'text-dark'}`} aria-current="page" >{languageJson.navbarName.home}</Link>
              </li>
              <li>
                <Link to="/aboutus" className={`nav-link navhover homenav  ${headerFixed ? 'nav-txt-color' : 'text-dark'}`} >{languageJson.navbarName.about}</Link>
              </li>
              <li>
                <Link to="/resource" className={`nav-link navhover homenav  ${headerFixed ? 'nav-txt-color' : 'text-dark'}`} >{languageJson.navbarName.resources}</Link>
              </li>
              <li>
                <Link to="/" className={`nav-link homenav ${headerFixed ? 'nav-txt-color' : 'text-dark'}`} href="#">
                  <div id="newheadnav" className={`customization_text ${headerFixed ? 'nav-txt-color customization_text2' : ''}`}>
                    <img src={oaklandlogo} alt="oaklandlogo" id="inner" className="d-block" />
                  </div>
                </Link>
              </li>
              <li>
                <Link to={{
                      pathname: "/ViewTruckTurnTimes",
                      state: "reports"
                    }} className={`nav-link homenav  ${headerFixed ? 'nav-txt-color' : 'text-dark'}`}>{languageJson.navbarName.reports}</Link>
              </li>
              <li> <Link to="/alerts" className={`nav-link homenav  ${headerFixed ? 'nav-txt-color' : 'text-dark'}`} >{languageJson.navbarName.alerts}</Link></li>
              <li>
                <Link to="/announcements" className={`nav-link homenav  ${headerFixed ? 'nav-txt-color' : 'text-dark'}`}>{languageJson.alertsAnnouncements}</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}
export default NavBar;